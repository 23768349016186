import React from "react";
import "../../assets/css/grid.css";
import "../../assets/css/base.css";
import "../../assets/css/style.css";
import "../../assets/css/reponsive.css";
import xtest_1 from "../../assets/images/review1.png";
import xbackpack from "../../assets/images/xbackpack.png.pagespeed.ic.FSTbvACRee.webp";
import xtest_2 from "../../assets/images/review2.webp";
import xisland_t from "../../assets/images/xisland_t.png.pagespeed.ic.VzskOYDdnJ.webp";
import xtest_3 from "../../assets/images/review3.jpg";
import kayak from "../../assets/images/kayak.png.webp";
export default function Testimonials() {
  return (
    <div className="testimonials">
      <div className="test_border"></div>
      <div className="grid wide">
        <div className="testimonials__body">
          <h2>Khách hàng nói gì về chúng tôi</h2>
          <div className="testimonials__body__items">
            <div className="row">
              <div className="col l-4 m-6 c-6 xs-12 s-12">
                <div className="testimonials__body__item">
                  <img
                    src={xtest_1}
                    alt=""
                  />
                  <div className="body__item--icon">
                    <img
                      src={xbackpack}
                      alt=""
                    />
                  </div>
                  <div className="body__item__container">
                    <div className="body__item__container__content">
                      <span>" Kỳ nghỉ tuyệt vời nhất "</span>
                      <p>Nhờ có CÔNG TY TNHH DU LỊCH THÁI ĐẠI  VIỆT đã giúp tôi có kỳ nghĩ tuyệt vời</p>
                      <div className="body__item__container--info">
                        <h3>Đinh Thị Việt Hà</h3>
                        <span>May 24, 2020</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col l-4 m-6 c-6 xs-12 s-12">
                <div className="testimonials__body__item">
                  <img
                    src={xtest_2}
                    alt=""
                  />
                  <div className="body__item--icon">
                    <img
                      src={xisland_t}
                      alt=""
                    />
                  </div>
                  <div className="body__item__container">
                    <div className="body__item__container__content">
                      <span>" Kỳ nghỉ tuyệt vời nhất "</span>
                      <p>Nhờ có CÔNG TY TNHH DU LỊCH THÁI ĐẠI  VIỆT đã giúp tôi có kỳ nghĩ tuyệt vời</p>
                      <div className="body__item__container--info">
                        <h3>Nguyễn Trung Hiếu</h3>
                        <span>May 30, 2020</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col l-4 hide-on-tablet_mobile">
                <div className="testimonials__body__item">
                  <img
                    src={xtest_3}
                    alt=""
                  />
                  <div className="body__item--icon">
                    <img src={kayak} alt="" />
                  </div>
                  <div className="body__item__container">
                    <div className="body__item__container__content">
                      <span>" Kỳ nghỉ tuyệt vời nhất "</span>
                      <p>Nhờ có CÔNG TY TNHH DU LỊCH THÁI ĐẠI  VIỆT đã giúp tôi có kỳ nghĩ tuyệt vời</p>
                      <div className="body__item__container--info">
                        <h3>Giáp Chí Cường</h3>
                        <span>May 30, 2020</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
