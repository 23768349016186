import React, { useEffect, useState } from "react";
import "../../assets/css/service.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Search from "../../components/Search/Search";
import xoffer from "../../assets/images/xoffer_5.jpg.pagespeed.ic.BPUGlc08kT.webp";
import xoffer6 from "../../assets/images/xoffer_6.jpg.pagespeed.ic.z1jAmiqjUy.webp";
import xpost from "../../assets/images/xpost.png.pagespeed.ic.8S9NJqfU1S.webp";
import xcompass from "../../assets/images/xcompass.png.pagespeed.ic.BMlGyHi2Dm.webp";
import xbicycle from "../../assets/images/xbicycle.png.pagespeed.ic.-D-2GN2myH.webp";
import xsailboat from "../../assets/images/xsailboat.png.pagespeed.ic.bAIbYdxQvf.webp";
import { Rating } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTours } from "../../redux/apiCalls";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import DOMPurify from "dompurify";
export default function Service() {
  const location = useLocation();
  const cat = location.pathname.split("/")[2];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [value, setValue] = useState(5);
  const [loadingItems, setLoadingItems] = useState(false);
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tour.tours);
  const [currentPage, setCurrentPage] = useState(1);
  const servicesPerPage = 2;

  useEffect(() => {
    getTours(cat, dispatch);
    setLoadingItems(true);
  }, [cat, dispatch]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({
      top: 500,
      left: 0,
      behavior: "smooth",
    });
  };
  const [loading, setLoading] = useState(true);
  const categories = useSelector((state) => state.category.categories);

  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = useSelector((state) =>
    state.tour.tours.slice(indexOfFirstService, indexOfLastService)
  );
  const totalPages = Math.ceil(tours.length / servicesPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  return (
    <div>
      <Navbar />
      <div className="service">
        <div className="service__background"></div>
        <div className="service__title">
          <h1>TOURS</h1>
        </div>
      </div>

      <Search />
      <div className="offers">
        <div className="offers__container">
          <div className="grid wide custom">
            <div className="row offers__container__items">
              {currentServices.map((tour) =>
                loadingItems ? (
                  <div className="col l-12 m-12 c-12 s-12">
                    <Link
                      to={`/tour/${tour._id}`}
                      key={tour._id}
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <div className="offer__container__item">
                        <div className="row">
                          <div className="col l-4 m-6 c-12 s-12">
                            <div className="offer__item__img--container">
                              <div
                                className="offer__item__img"
                                style={{
                                  backgroundImage: `url(${tour.imgs[0]})`,
                                }}
                              ></div>
                              <span className="offer__container__item-date">
                                {tour.time}
                              </span>
                              <div className="offer__container__item-name">
                                <a> {tour.title}</a>
                              </div>
                            </div>
                          </div>
                          <div className="l-8 m-6 c-12 s-12">
                            <div className="offer__container__item--content">
                              <div className="offer__container__item__review">
                                <div className="offer__container__item__review--text">
                                  <h3>Cực Kì Tốt</h3>
                                  <span>100 người đánh giá</span>
                                </div>
                                <span>8.1</span>
                              </div>
                              <div className="offers__item__price">
                                {tour.price} VNĐ
                                <span>trọn gói</span>
                              </div>
                              <div className="item_rating">
                                <Rating
                                  name="read-only"
                                  value={value}
                                  readOnly
                                  className="item_rating_i"
                                />
                              </div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(tour.descSummary),
                                }}
                              ></p>
                              <div className="offer__item--icon">
                                <img src={xpost} alt="" />
                                <img src={xcompass} alt="" />
                                <img src={xbicycle} alt="" />
                                <img src={xsailboat} alt="" />
                              </div>
                              <div className="offers__btn">
                                <a>Đặt Ngay</a>
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <Box
                    sx={{ overflow: "hidden" }}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"210px"}
                      height={"161px"}
                    />
                    <Skeleton />
                    <Skeleton width="210px" animation="wave" />
                  </Box>
                )
              )}
            </div>
            {totalPages > 1 && (
              <div className="pagination">
                {pageNumbers.map((pageNumber) => (
                  <span
                    key={pageNumber}
                    className={`page-number ${
                      pageNumber === currentPage ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(pageNumber)}
                  >
                    {pageNumber}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
