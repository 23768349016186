import React, { useEffect, useState } from "react";
import "../../assets/css/contact.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import xman from "../../assets/images/xman.png.pagespeed.ic.EFVcLSPoud.png";
import angiatour from "../../assets/images/THAI DAI VIET.png";
import placeholder from "../../assets/images/placeholder.svg";
import phone from "../../assets/images/phone-call.svg";
import message from "../../assets/images/message.svg";
import planet from "../../assets/images/planet-earth.svg";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { addMessage } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Contact() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [inputs, setInputs] = useState({});
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleAddOrder = (e) => {
    // e.preventDefault();
    const message = {
      ...inputs,
    };
    addMessage(message, dispatch);
  };
  const handleSubmit = () => {
    if (inputs.fullname === undefined || inputs.phone === undefined) {
      toast.warning("Vui lòng nhập đầy đủ thông tin");
    } else {
      if (!isNaN(inputs.fullname)) {
        toast.warning("Họ và tên phải nhập chữ");
      } else {
        if (isNaN(inputs.phone)) {
          toast.warning("Số Điện Thoại Phải Nhập Số");
        } else {
          handleAddOrder();
        }
      }
    }
  };
  return (
    <div>
      <Navbar />
      <div className="Pagecontact">
        <div className="Pagecontact__background"></div>
        <div className="Pagecontact__title">
          <h1>Liên Hệ</h1>
        </div>
      </div>

      <div className="Pagecontact__container">
        <div className="grid wide custom">
          <div className="Pagecontact__container">
            <div className="row">
              <div className="col l-12 m-12 c-12">
                <div className="Pagecontact__container__form">
                  <div className="Pagecontact__container__form--title">
                    <h2>Liên Hệ</h2>
                  </div>
                  <div className="Pagecontact__form">
                    <input
                      className="Pagecontact_form_name"
                      name="fullname"
                      placeholder="Nhập họ tên"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      name="email"
                      placeholder="Nhập email"
                      onChange={handleChange}
                      className="Pagecontact_form_email"
                    />
                    <input
                      type="text"
                      name="phone"
                      placeholder="Nhập số điện thoại"
                      onChange={handleChange}
                      className="Pagecontact_form_subject"
                    />
                    <textarea
                      id=""
                      cols="30"
                      rows="10"
                      name="message"
                      placeholder="Nhập lời nhắn"
                      onChange={handleChange}
                    ></textarea>
                    <div className="row">
                      <div className="l-12 m-12 c-12 text-center">
                        <button
                          className="form_submit_button"
                          onClick={handleSubmit}
                        >
                          {" "}
                          Gửi thông tin<span></span>
                          <span></span>
                          <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid wide custom">
        <div className="Pagecontact__about">
          <div className="row">
            <div className="col l-5 m-6 c-12 img padding">
              <img src={xman} alt="" />
            </div>
            <div className="col l-4 m-6 c-12 padding">
              <div className="Pagecontact__about--logo">
                <a>
                  <img src={angiatour} alt=" " />
                </a>
              </div>
              <p className="Pagecontact__about-text">
                Tìm kiếm cảm hứng? <br />
                Hãy để chúng tôi giúp bạn tìm nơi hoàn hảo. Điểm đến lý tưởng
                cho kỳ nghỉ cuối tuần hoặc tuần trăng mật
              </p>
              <ul className="Pagecontact__about--social">
                <li>
                  <a>
                    <FacebookOutlinedIcon className="i" />
                  </a>
                </li>
                <li>
                  <a>
                    <PhoneInTalkOutlinedIcon className="i" />
                  </a>
                </li>
                <li>
                  <a>
                    <InstagramIcon className="i" />
                  </a>
                </li>
                <li>
                  <a>
                    <YouTubeIcon className="i" />
                  </a>
                </li>
                <li>
                  <a>
                    <LinkedInIcon className="i" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col l-3 m-12 c-12 padding">
              <div className="Pagecontact__container__info">
                <div className="Pagecontact__container__content">
                  <div className="Pagecontact__container__info">
                    <div className="Pagecontact__container__info--img">
                      <img src={placeholder} alt=" " />
                    </div>
                    <a
                      href="https://goo.gl/maps/TQRsKP9yH615uqJy7"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        Địa chỉ: Số 294 đường Bùi Hữu Nghĩa, KP 1, Phường Tân
                        Vạn, Thành phố Biên Hoà, Tỉnh Đồng Nai
                      </span>
                    </a>
                  </div>
                  <div className="Pagecontact__container__info">
                    <div className="Pagecontact__container__info--img">
                      <img src={placeholder} alt=" " />
                    </div>
                    <a
                      href="https://goo.gl/maps/Gvb6S7zAE9KQvRPbA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        Văn Phòng TPHCM: 43/8/2 đường Cầu Xéo, P.Tân Quý, Q.Tân
                        Phú, TP.HCM
                      </span>
                    </a>
                  </div>
                  <div className="Pagecontact__container__info">
                    <div className="Pagecontact__container__info--img">
                      <img src={message} alt=" " />
                    </div>
                    <a href="mailto:angiatourist@gmail.com">
                      <span> truyen.thaidaiviet@gmail.com</span>
                    </a>
                  </div>
                  <div className="Pagecontact__container__info">
                    <div className="Pagecontact__container__info--img">
                      <img src={phone} alt=" " />
                    </div>
                    <a href="tel:+0903666576">
                      <span> 0947.154.565 </span>
                    </a>{" "}
                  </div>
                  <div className="Pagecontact__container__info">
                    <div className="Pagecontact__container__info--img">
                      <img src={planet} alt=" " />
                    </div>
                    <a
                      href="https://angiatourist.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span> truyen.thaidaiviet@gmail.com</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.6398759345916!2d106.8243007113054!3d10.91494958919754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174ded7ad9dc9ab%3A0xe1880f2d6aa3372b!2zMjk0IMSQxrDhu51uZyBCw7lpIEjhu691IE5naMSpYSwgVMOibiBWw6JuLCBUaMOgbmggcGjhu5EgQmnDqm4gSMOyYSwgxJDhu5NuZyBOYWksIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1691799513990!5m2!1svi!2s"
              width="100%"
              height="450"
              style={{ border: "none" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              class="mapIframe"
              title="map"
            ></iframe>
            <div className="iframe-overlay"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
